import React, { useState } from "react";
import hamBug from "../Image/hambug.svg";
import Menu from "./Menu";
import Pop1 from "./Pop1";
import img1 from "../Image/abImg1.svg";
import img2 from "../Image/abImg2.svg";
import img3 from "../Image/abImg3.svg";
import img4 from "../Image/abImg4.svg";
import appLogo from "../Image/appLogo.svg";
import { useMediaQuery } from "react-responsive";

interface PProps {
  abtClick?: any;
  AppF?: any;
  BrandV?: any;
  Faq?: any;
}
export default function NavBar({ abtClick, AppF, BrandV, Faq }: PProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [showAbt, setShowAbt] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 677 });
  const isTab = useMediaQuery({ maxWidth: 1250, minWidth: 767 });
  const sliderImageUrl = [img1, img2, img3, img4];
  return (
    <>
      <div className="navBar">
        <div className="logo">
          {/* <img src={appLogo} alt="" /> */}
          <h3>StableBlocks</h3>
        </div>
        <div
          className="hambug"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <img src={hamBug} alt="" />
        </div>
      </div>
      {showMenu && (
        <Menu
          AppF={() => {
            setShowMenu(false);
            AppF();
          }}
          BrandV={() => {
            setShowMenu(false);
            BrandV();
          }}
          Faq={() => {
            setShowMenu(false);
            Faq();
          }}
          abtClick={() => {
            setShowMenu(false);
            setShowAbt(true);
          }}
          visible={showMenu}
          onclick={() => {
            setShowMenu(false);
          }}
        />
      )}
      {showAbt && (
        <Pop1
        sliderImageUrl={sliderImageUrl}
          closePop={() => setShowAbt(false)}
          bText={
            <p>
              At StableBlocks, we believe that investing should be more than
              just about returns —
              <span>it’s about building the lifestyle you want.</span>
              {isMobile  || isTab && (
                <>
                  <br />
                  <br />
                </>
              )}{" "}
              We connect you with promising projects, talents, unique brand
              vouchers, and tools to help you make informed decisions. Whether
              you're investing for returns or reinvesting in your own
              experiences, StableBlocks is here for the entire journey.
            </p>
          }
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
        />
      )}
    </>
  );
}
