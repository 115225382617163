import { RequestHandler } from "./Request";
const request = new RequestHandler();

export function Sub(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("/user/add-website-lead", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
