import React, { useState } from "react";
import minus from "../Image/minus.svg";
import lPlus from "../Image/lucidePlus.svg";
import FadeInSection from "./FadInSection";

export default function FAQs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      question: "What can I do on StableBlocks?",
      answer: `On StableBlocks, you can explore and invest in a wide range of curated projects across industries like real estate, entertainment, infrastructure and lots more. You get to manage and track your portfolio’s performance. You can also do other exciting things like read exclusive stories on projects and project owners, get updates on projects progress, set portfolio goals and get investment recommendations based on your goals, connect an external wallet, and even Invest in brands you love and enjoy exclusive perks that elevate your lifestyle`,
    },
    {
      question: "Does StableBlocks have a free version?",
      answer:
        `No, a subscription is required to create and fully utilize your StableBlocks account. However, if your subscription is inactive you can still access basic features like your dashboard.`,
    },
    {
      question: "How do brand vouchers work?",
      answer:
        "With StableBlocks brand vouchers, you pay upfront to access services or products from a business for a year. If you don’t use up the full voucher value by the end of the year, you’ll receive the remaining balance in cash.",
    },
    {
      question: "How can I invest on StableBlocks?",
      answer:
        "To invest on StableBlocks, simply create an account, browse the listed projects, choose one that fits your interests or goals, and fund the project with your desired amount through your connected wallet or StableBlocks wallet.",
    },
    {
      question: "What can I do on StableBlocks without an active subscription?",
      answer:
        "A subscription is required to create and fully utilize your StableBlocks account. However, without a subscription you can do basic things like view your dashboard and portfolio.",
    },
    {
      question: "Where is StableBlocks available?",
      answer:
        "StableBlocks is currently available on iOS only.",
    },
    {
      question: "What is the StableBlocks reserve card?",
      answer:
        "With your portfolio as collateral you can access credit easily and securely. The reserve card allows you to leverage your digital assets for real-time liquidity.",
    },
    {
      question: "Can I share my StableBlocks subscription?",
      answer:
        "Currently, StableBlocks subscriptions are tied to individual accounts, meaning each user must have their own subscription.",
    },
  ];
  return (
    <div className="faqCont">
      {faqs.map((item, index) => (
        <FadeInSection>
          <div className="faqContent" onClick={() => toggleAnswer(index)}>
            <div className="faqHeaderCont" onClick={() => toggleAnswer(index)}>
              <p className="faqQuestion">{item.question}</p>
              {activeIndex === index ? (
                <>
                  <img src={minus} alt="" className="icon" />
                </>
              ) : (
                <>
                  <img src={lPlus} alt="" className="icon" />
                </>
              )}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        </FadeInSection>
      ))}
    </div>
  );
}
