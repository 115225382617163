import React from "react";
import logo from "./logo.svg";
import "./App.css";
import HomeScreen from "./Screens/HomeScreen";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import TermsOfUse from "./Screens/TermsOfUse";
import PrivacyandPolicies from "./Screens/PrivacyAndPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="TermsOfUse" element={<TermsOfUse />} />
        <Route path="PrivacyAndPolicies" element={<PrivacyandPolicies />} />
      </Routes>
    </Router>
  );
}

export default App;
