import React, { useEffect, useState } from "react";
import Logo from "../Image/logo.svg";
import ig from "../Image/ig.svg";
import In from "../Image/in.svg";
import x from "../Image/x.svg";
import ng from "../Image/ng.png";
import us from "../Image/us.png";
import { useMediaQuery } from "react-responsive";
import Pop1 from "./Pop1";
import img1 from "../Image/abImg1.svg";
import img2 from "../Image/abImg2.svg";
import img3 from "../Image/abImg3.svg";
import img4 from "../Image/abImg4.svg";
import { Link } from "react-router-dom";
import indictor from "../Image/ZZ5H.gif";
import { log } from "console";
import { Sub } from "../handlers/Sub";

interface PProps {
  abtPress?: any;
  colabPress?: any;
  pCastPress?: any;
  ppPress?: any;
  touPress?: any;
}
export default function Footer({
  abtPress,
  colabPress,
  pCastPress,
  ppPress,
  touPress,
}: PProps) {
  const [showAbt, setShowAbt] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 677 });
  const isTab = useMediaQuery({ maxWidth: 1250, minWidth: 767 });
  const sliderImageUrl = [img1, img2, img3, img4];
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [success, setSucces] = useState("");
  const [loadeing, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // Function to validate email
  const validateEmail = (email: string) => {
    if (!email.trim()) {
      return "Email is required.";
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      return "Invalid email, please enter a correct one";
    }
    return ""; // No errors
  };

  const sub = async () => {
    try {
      const sub = await Sub({
        email: email,
      });
      console.log(sub);
      if (sub?.id) {
        // @ts-ignore
        setSucces("Thank you for subscribing! Stay tuned for exciting news!");
        setLoading(false);
        setDisabled(true);
        setTimeout(() => {
          setEmail("");
          setSucces("");
          setDisabled(false)
        }, 2000);
        // localStorage.setItem("email", email);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const emailError = validateEmail(email);
    if (emailError) {
      setErrors({ email: emailError });
    } else {
      setErrors({ email: "" });
      setLoading(true);
      sub();
      console.log("Form submitted successfully!");
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem("email")) {
  //     // @ts-ignore
  //     setEmail(localStorage.getItem("email"));
  //   }
  // }, []);
  return (
    <>
      <div className="footer">
        <div className="footerSection1">
          <div className="fWrapper">
            <h4 className="footerHeader">
              Join our newsletter to keep up to date with us!
            </h4>
          </div>
          <div>
            <div className="inputCont">
              <div className="inputWrapper" style={{ display: "flex", justifyContent: "space-between"}}>
                <input
                  type="text"
                  className="input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              <button
                className="subscribe"
                onClick={handleSubmit}
                disabled={disabled}
              >
                {/* Subcribe */}
                {loadeing ? (
                  <img src={indictor} alt="" className="indi" />
                ) : (
                  "Subscribe"
                )}
              </button>
              </div>
            {errors.email && <span className="errorText">{errors.email}</span>}
            {success && <span className="successText">{success}</span>}
            </div>
          </div>
        </div>
        <div className="footerSection2">
          <div className="content1">
            <img src={Logo} alt="" />
            <h4>StableBlocks</h4>
            <p>Redefining investment.</p>
            <button className="btn fBtn2">Get StableBlocks</button>
          </div>
          <div className="fContentWrapper">
            <div className="content2">
              <p className="ph">Company</p>
              <p className="pp">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    abtPress();
                  }}
                >
                  About StableBlocks
                </a>
              </p>
              <p className="pp">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    colabPress();
                  }}
                >
                  Collaborate with us
                </a>
              </p>
              <p className="pp">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    pCastPress();
                  }}
                >
                  Our podcast
                </a>
              </p>
              <p className="pp">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    ppPress();
                  }}
                >
                  Privacy policy
                </a>
              </p>

              <p className="pp">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    touPress();
                  }}
                >
                  Terms of Use
                </a>
              </p>
            </div>
            <div className="content3">
              <p className="ph">Download</p>
              <p className="pp pp1">iOS</p>
              <p className="ph">Follow us</p>
              <div className="social">
                <a
                  href="https://www.instagram.com/trendx.space/"
                  target="_blank"
                >
                  <img src={ig} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/trendx/mycompany/"
                  target="_blank"
                >
                  <img src={In} alt="" />
                </a>
                <a
                  href="https://twitter.com/trendxapp?t=Uuzys2o8udzE7tgTwGIYqQ&s=09"
                  target="_blank"
                >
                  <img src={x} alt="" />
                </a>
              </div>
            </div>
            <div className="content4">
              <p className="ph">Contact us</p>
              <p className="pp">
                <a href="mailto:support@usestableblocks.com" target="_blank">Support@usestableblocks.com</a>
              </p>
              <p className="pp">
                <a href="">+234 803 5055 5120</a>
              </p>
              <p className="pp">
                <a href="">+1 545 787 665 7865</a>
              </p>
            </div>
          </div>
        </div>
        <div className="lastFooterSec">
          <div className="localWrap">
            <div className="ngLocation">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // alignItems: "center",
                }}
              >
                <img
                  src={ng}
                  alt=""
                  style={{
                    width: 15,
                    height: 15,
                    objectFit: "contain",
                    marginTop: 2,
                    marginRight: 3,
                  }}
                />

                <p className="country">Nigeria</p>
              </div>
              <p className="address">
              The Factory Place, Tinapa Business Resort, Calabar, Cross River State, Nigeria
              </p>
            </div>
            <div className="ngLocation usLocal">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // alignItems: "center",
                }}
              >
                <img
                  src={us}
                  alt=""
                  style={{
                    width: 15,
                    height: 15,
                    objectFit: "contain",
                    marginTop: 2,
                    marginRight: 3,
                  }}
                />
                <p className="country">USA</p>
              </div>
              <p className="address">
                1007 N Orange Street, Wilmington, Delaware USA
              </p>
            </div>
          </div>

          <div className="copyWrap">
            <p className="copyright">© 2024 StableBlocks</p>
          </div>
        </div>
      </div>
    </>
  );
}
